import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, array, number, object, string } from "yup";
import type { LegType } from "../libs/common/models/src/lib/enums/leg.enum";
import { Flex } from "./GenericFlex";
import {
	GenericFormButtons,
	InputMultiSelect,
	InputSelect,
	InputText,
	type LoadOptionsFn,
} from "./GenericForm";
import { GenericPage } from "./GenericPage";
import {
	type TypedGridColumnProps,
	authenticationApi,
	jobApi,
	legTypeNames,
	toasted,
	useLookupCurrencies,
	useLookupLegTypes,
	useLookupOrganisations,
} from "./helpers";

const BusinessUnitSchema = object({
	id: string().label("ID"),
	name: string().required().label("Name"),
	internalCode: string().required().label("Internal Code"),
	description: string().required().label("Description"),
	address: string().label("Address"),
	email: string().label("Email"),
	zipCode: string().label("Zip Code"),
	phone: string().label("Phone"),
	fax: string().label("Fax"),
	organisationId: string().required().label("Organisation"),
	currencyId: number().label("Currency"),
	logo: string().label("Logo"),
	transferCustomerAccountCode: string().label("Transfer Customer Account Code"),
	availableLegTypes: array()
		.of(number().required().label("Leg Type Available"))
		.label("Leg Types Available")
		.required(),
	availableStageTypes: array()
		.of(number().required().label("Stage Type Available"))
		.label("Stage Types Available")
		.required(),
});
type BusinessUnitFormObject = InferType<typeof BusinessUnitSchema>;

type BusinessUnitFormProps = {
	defaultValues?: Partial<BusinessUnitFormObject>;
	onSubmit: (data: BusinessUnitFormObject) => void;
	lookupOrganisations: LoadOptionsFn;
	lookupCurrencies: LoadOptionsFn;
	lookupLegTypes: LoadOptionsFn;
};
const BusinessUnitForm = ({
	defaultValues,
	onSubmit,
	lookupOrganisations,
	lookupCurrencies,
	lookupLegTypes,
}: BusinessUnitFormProps) => {
	const { handleSubmit, reset, control } = useForm<BusinessUnitFormObject>({
		resolver: yupResolver(BusinessUnitSchema),
		defaultValues,
	});
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit((data) => onSubmit?.(data))}
		>
			<Flex>
				<div>
					<InputText
						control={control}
						schema={BusinessUnitSchema}
						name="name"
					/>
					<InputText
						control={control}
						schema={BusinessUnitSchema}
						name="internalCode"
					/>
					<InputText
						control={control}
						schema={BusinessUnitSchema}
						name="description"
					/>
					<InputText
						control={control}
						schema={BusinessUnitSchema}
						name="address"
					/>
					<InputText
						control={control}
						schema={BusinessUnitSchema}
						name="email"
					/>
					<InputText
						control={control}
						schema={BusinessUnitSchema}
						name="zipCode"
					/>
					<InputText
						control={control}
						schema={BusinessUnitSchema}
						name="phone"
					/>
				</div>
				<div>
					<InputText control={control} schema={BusinessUnitSchema} name="fax" />
					<InputSelect
						control={control}
						schema={BusinessUnitSchema}
						name="organisationId"
						loadOptions={lookupOrganisations}
					/>
					<InputSelect
						control={control}
						schema={BusinessUnitSchema}
						name="currencyId"
						loadOptions={lookupCurrencies}
					/>
					<InputText
						control={control}
						schema={BusinessUnitSchema}
						name="logo"
					/>
					<InputText
						control={control}
						schema={BusinessUnitSchema}
						name="transferCustomerAccountCode"
					/>
					<InputMultiSelect
						control={control}
						schema={BusinessUnitSchema}
						name="availableLegTypes"
						loadOptions={lookupLegTypes}
					/>
					<InputMultiSelect
						control={control}
						schema={BusinessUnitSchema}
						name="availableStageTypes"
						loadOptions={lookupLegTypes}
					/>
				</div>
			</Flex>
			<GenericFormButtons onReset={() => reset(defaultValues)} />
		</form>
	);
};
const BusinessUnitFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<BusinessUnitFormProps, "onSubmit" | "defaultValues">) => {
	const lookupOrganisations = useLookupOrganisations();
	const lookupCurrencies = useLookupCurrencies();
	const lookupLegTypes = useLookupLegTypes();
	return (
		<BusinessUnitForm
			defaultValues={defaultValues}
			onSubmit={async (data) => {
				const { id, ...rest } = data;
				const processData = async () => {
					if (id)
						await authenticationApi.businessUnit.businessUnitUpdate({
							id,
							...rest,
						});
					else await authenticationApi.businessUnit.businessUnitCreate(rest);
				};
				await toasted(
					processData(),
					id ? "Updating Business Unit" : "Creating Business Unit",
				);
				onSubmit(data);
			}}
			lookupOrganisations={lookupOrganisations}
			lookupCurrencies={lookupCurrencies}
			lookupLegTypes={lookupLegTypes}
		/>
	);
};

type BusinessUnit = BusinessUnitFormObject & {
	id: string;
	currencyString: string;
	organisationString: string;
	availableLegTypesString: string;
	availableStageTypesString: string;
};
const defaultColumns: TypedGridColumnProps<BusinessUnit>[] = [
	{ field: "id", title: "ID" },
	{ field: "name", title: "Name" },
	{ field: "currencyString", title: "Currency" },
	{ field: "address", title: "Address" },
	{ field: "description", title: "Description" },
	{ field: "email", title: "Email" },
	{ field: "fax", title: "Fax" },
	{ field: "internalCode", title: "Internal Code" },
	{ field: "organisationString", title: "Organisation" },
	{ field: "phone", title: "Phone" },
	{
		field: "transferCustomerAccountCode",
		title: "Transfer Customer Account Code",
	},
	{ field: "zipCode", title: "Zip Code" },
	{ field: "availableLegTypesString", title: "Available Leg Types" },
	{ field: "availableStageTypesString", title: "Available Stage Types" },
];
const useFetchData = (): ComponentProps<
	typeof GenericPage<BusinessUnit>
>["data"] => {
	const _businessUnits = useQuery({
		queryKey: ["authenticationApi.businessUnit.businessUnitList"],
		queryFn: () =>
			authenticationApi.businessUnit
				.businessUnitList({})
				.then((x) => x.data.data),
		initialData: [],
	});
	const _currencies = useQuery({
		queryKey: ["jobApi.currency.currencyList"],
		queryFn: () => jobApi.currency.currencyList({}).then((x) => x.data.data),
		initialData: [],
	});
	const _organisations = useQuery({
		queryKey: ["authenticationApi.organisation.organisationList"],
		queryFn: () =>
			authenticationApi.organisation
				.organisationList({})
				.then((x) => x.data.data),
		initialData: [],
	});
	const businessUnits = useMemo(
		() =>
			_businessUnits.data.map(
				(x): BusinessUnit => ({
					id: x.id,
					name: x.name,
					address: x.address ?? "",
					description: x.description,
					currencyString:
						_currencies.data.find((y) => y.id === x.currencyId)?.code ?? "",
					email: x.email ?? "",
					fax: x.fax ?? "",
					internalCode: x.internalCode,
					organisationString:
						_organisations.data.find((y) => y.id === x.organisationId)?.name ??
						"",
					phone: x.phone ?? "",
					transferCustomerAccountCode: x.transferCustomerAccountCode ?? "",
					zipCode: x.zipCode ?? "",
					logo: x.logo ?? "",
					currencyId: x.currencyId ?? undefined,
					organisationId: x.organisationId,
					availableLegTypes: x.availableLegTypes,
					availableLegTypesString: x.availableLegTypes
						.map((x: LegType) => legTypeNames[x])
						.join(", "),
					availableStageTypes: x.availableStageTypes,
					availableStageTypesString: x.availableStageTypes
						.map((x: LegType) => legTypeNames[x])
						.join(", "),
				}),
			),
		[_businessUnits.data, _currencies.data, _organisations.data],
	);
	return {
		data: businessUnits,
		retry: _businessUnits.refetch,
		loading: _businessUnits.isFetching,
	};
};
export const BusinessUnitsPage = () => {
	const data = useFetchData();
	const handleDelete = (id: string) =>
		toasted(
			authenticationApi.businessUnit.businessUnitDelete(id).then(data.retry),
			"Deleting Business Unit",
		);
	const getForm = (
		id: string | undefined,
		onSubmit: (data: BusinessUnitFormObject) => void,
	) => {
		let defaultValues: Partial<BusinessUnitFormObject> = {};
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<BusinessUnitFormWithDTO
				onSubmit={onSubmit}
				defaultValues={defaultValues}
			/>
		);
	};
	return (
		<GenericPage
			pageTitle="Business Units"
			name="Business Unit"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};
