import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import {
	Field,
	Form,
	FormElement,
	type FormProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { type InferType, boolean, number, object, string } from "yup";
import { recycleBinIcon } from "../libs/common/models/src/lib/constants/icon.constants";
import { CelerumDocument } from "../libs/common/ui/src/lib/components/celerum-document/celerum-document.component";
import { CelerumFormUploadInput } from "../libs/common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import {
	type AttachmentCreatePayload,
	type AttachmentUsageType,
	ContentType,
} from "./api/JobApi";
import { jobApi } from "./helpers";
import { useDialog } from "./helpersReact";

export const DocumentSchema = object({
	id: number().required().label("Id"),
	name: string().required().label("Name"),
	path: string().required().label("Path"),
	isPod: boolean().required().label("Is Pod"),
	isInvoice: boolean().required().label("Is Invoice"),
});

export type DocumentFormObject = InferType<typeof DocumentSchema>;

const downloadFile = (url: string) => {
	const link = document.createElement("a");
	link.href = url;

	// Append the link to the body and trigger a click to start the download
	document.body.appendChild(link);
	link.click();

	// Clean up
	document.body.removeChild(link);
};

export const useDocumentsForm = () => {
	const [id, setId] = useState<number>(0);
	const [type, setType] = useState<AttachmentUsageType>(0);
	const [documents, setDocuments] = useState<DocumentFormObject[]>([]);
	const [dataRetry, setDataRetry] = useState<(() => void) | null>(null);
	const [isInteractive, setIsInteractive] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedDocument, setSelectedDocument] = useState<
		DocumentFormObject | undefined
	>(undefined);

	const styles = {
		container: {
			marginBottom: "20px",
			width: "400px",
		},
		item: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "flex-start",
			marginBottom: "1.5vh",
		},
		icon: {
			alignSelf: "center",
			delete: {
				cursor: "pointer",
			},
		},
		confirm: {
			cursor: "pointer",
			padding: "4px",
			ok: {
				color: "red",
			},
			cancel: {
				color: "black",
			},
			hover: {
				opacity: 0.8,
				borderRadius: "4px",
				backgroundColor: "#f2f2f2",
			},
		},
		field: {
			marginTop: "20px",
			marginBottom: "20px",
		},
		submitButtonContainer: {
			display: "flex",
			justifyContent: "end",
			marginTop: "10px",
		},
		loader: {
			color: "#0b615b",
		},
		verticalWrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			height: "100%",
		},
	};

	const handleDelete = useCallback(
		async (attachmentId: number) => {
			if (isLoading) {
				return;
			}

			setIsLoading(true);

			await jobApi.attachment
				.attachmentDelete(attachmentId)
				.then(() => {
					setDocuments(documents.filter((doc) => doc.id !== attachmentId));
					handleDataRetry();
					toast.success("Attachments were successfully inserted.");
				})
				.catch(() => {
					toast.error("Could not insert attachment. Please try again.");
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[isLoading, documents.filter],
	);

	const handleDownload = useCallback(
		async (attachmentId: number) => {
			if (isLoading) {
				return;
			}

			setIsLoading(true);

			await jobApi.attachment
				.attachmentDetail(attachmentId)
				.then((response) => {
					downloadFile(response.data);
				})
				.catch(() => {
					toast.error("Could not download the attachment. Please try again.");
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[isLoading],
	);
	const handleSubmit = useCallback(
		async (dataItem: Parameters<NonNullable<FormProps["onSubmit"]>>[0]) => {
			setIsLoading(true);

			const formData = { files: [] as Blob[] } as AttachmentCreatePayload;
			const newDataItem = { ...dataItem };

			if (newDataItem.files) {
				for (const file of newDataItem.files) {
					if (!documents.some((item) => item.name === file.name)) {
						formData.files?.push(file.getRawFile?.() || new Blob());
					}
				}
			}

			await jobApi.attachment
				.attachmentCreate(
					{
						entityId: id,
						isPod: false,
						usage: type,
					},
					formData,
					{
						headers: {
							"Content-Type": ContentType.FormData,
						},
					},
				)
				.then(() => {
					toast.success("Document(s) attached...");
					handleDataRetry();
					toggleDialog(false);
				})
				.catch(() => {
					toast.error("Could not save the attachment(s). Please try again.");
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[documents.some, id, type],
	);

	const handleDataRetry = async () => {
		if (dataRetry) {
			dataRetry();
		}
	};

	const dialogBody = useMemo(
		() => (
			<>
				<div>
					{isLoading && (
						<div style={styles.verticalWrapper}>
							<Loader
								style={styles.loader}
								size={"small"}
								type="converging-spinner"
							/>
						</div>
					)}
				</div>

				<div style={styles.container}>
					{documents.length > 0 ? (
						<div>
							{documents.map((item) => (
								<div key={item.id} style={styles.item}>
									<CelerumDocument
										documentName={item.name}
										onClick={() => {
											handleDownload(item.id);
										}}
									/>

									{isInteractive && (
										<div style={styles.icon}>
											{selectedDocument !== item ? (
												<SvgIcon
													width={18}
													icon={recycleBinIcon}
													style={styles.icon.delete}
													onClick={() => {
														setSelectedDocument(item);
													}}
												/>
											) : (
												<div>
													<span
														style={{
															...styles.confirm,
															...styles.confirm.cancel,
														}}
														onClick={() => setSelectedDocument(undefined)}
														onKeyUp={() => setSelectedDocument(undefined)}
													>
														Cancel
													</span>
													<span
														style={{ ...styles.confirm, ...styles.confirm.ok }}
														onClick={() => {
															selectedDocument &&
																handleDelete(selectedDocument.id);
														}}
														onKeyUp={() => {
															selectedDocument &&
																handleDelete(selectedDocument.id);
														}}
													>
														OK
													</span>
												</div>
											)}
										</div>
									)}
								</div>
							))}
						</div>
					) : (
						<p>No documents found.</p>
					)}
				</div>

				<Form
					onSubmit={handleSubmit}
					render={(formRenderProps) => (
						<FormElement>
							{isInteractive && (
								<fieldset className="k-form-fieldset">
									<div style={styles.field}>
										<Field
											label="Upload documents"
											name="files"
											component={CelerumFormUploadInput}
										/>
									</div>
								</fieldset>
							)}

							<div style={styles.submitButtonContainer}>
								<Button type="submit" disabled={!formRenderProps.allowSubmit}>
									Submit
								</Button>
							</div>
						</FormElement>
					)}
				/>
			</>
		),
		[
			documents,
			selectedDocument,
			isLoading,
			isInteractive,
			handleSubmit,
			handleDownload,
			handleDelete,
		],
	);

	const [toggleDialog, documentsForm] = useDialog(dialogBody, "View Documents");

	const showDocumentsFor = useCallback(
		(
			id: number,
			_type: AttachmentUsageType,
			documents: DocumentFormObject[],
			dataRetry: () => void,
		) => {
			setId(id);
			setType(_type);
			setDocuments(documents);
			setDataRetry(() => dataRetry);

			setIsInteractive(true);
			toggleDialog(true);
		},
		[toggleDialog],
	);

	return { showDocumentsFor, documentsForm };
};
