import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import type { SelectionRange } from "@progress/kendo-react-dateinputs";
import { useQuery } from "@tanstack/react-query";
import { sumBy } from "lodash";
import { type ComponentProps, Fragment, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTitle } from "react-use";
import { LegsGrid } from "./LegsGrid";
import { TypedDropDownButton } from "./TypedDropDownButton";
import {
	type LegAction,
	type LegActions,
	LegStatusType,
	TableNameType,
	type TypedGridColumnProps,
	jobApi,
	legStatusTypeNames,
	legTypeNames,
	supplierInvoiceTypeNames,
	toCurrency,
	toDatetimeString,
	toasted,
	useLegStatus,
} from "./helpers";
import {
	useAuditForm,
	useGenericDateRangePicker,
	useGenericLegStatusFilter,
	useGoodsSelector,
} from "./helpersReact";

type LegsGridProps = ComponentProps<typeof LegsGrid>;
type Leg = LegsGridProps["data"][number];

type StatusItem = {
	text?: string;
	action: LegsGridAction;
	status?: LegStatusType;
	color?: string;
	data: Leg;
};
type LegsGridAction =
	| "view"
	| "cancel"
	| "delete"
	| "pause"
	| "resume"
	| LegAction;

const Status = ({
	statusString,
	color,
}: { statusString: string; color?: string }) => (
	<span>
		<Icon name="circle" style={{ color }} />
		{statusString}
	</span>
);

const StatusItemRender = ({ item }: { item: StatusItem }) => (
	<Status statusString={item.text ?? ""} color={item.color ?? ""} />
);

const useExtraColumns = (handleAction: (item: StatusItem) => void) => {
	const { availableStatuses, getLegColor } = useLegStatus();
	const getPossibleStatuses = useCallback(
		(data: Leg) =>
			availableStatuses(data.type).map(
				(x): StatusItem => ({
					text: legStatusTypeNames[x.id],
					action: "allowChangeStatus",
					status: x.id,
					data,
					color: x.color,
				}),
			),
		[availableStatuses],
	);
	return useMemo(() => {
		const _columns: TypedGridColumnProps<Leg>[] = [
			{
				title: "Status",
				cell: ({ dataItem }) => {
					const color = getLegColor(dataItem.status) ?? "black";
					return (
						<td>
							<TypedDropDownButton
								size="small"
								items={[...getPossibleStatuses(dataItem)]}
								itemRender={StatusItemRender}
								onItemClick={(x) => handleAction(x.item)}
								popupSettings={{ animate: false }}
								text={
									<Status statusString={dataItem.statusString} color={color} />
								}
								style={{ borderColor: color }}
							/>
						</td>
					);
				},
				field: "statusString",
			},
			{
				title: "Actions",
				cell: ({ dataItem }) => {
					const items: StatusItem[] = [];
					if (dataItem.status === LegStatusType.Paused) {
						items.push({
							text: "Resume",
							action: "resume",
							data: dataItem,
						});
					} else {
						items.push({
							text: "Pause",
							action: "pause",
							data: dataItem,
						});
					}
					if (dataItem.status === LegStatusType.New) {
						items.push({
							text: "Delete",
							action: "delete",
							data: dataItem,
						});
					} else {
						items.push({
							text: "Cancel",
							action: "allowDelete",
							data: dataItem,
						});
					}

					if (dataItem.actions.allowAudit) {
						items.push({
							text: "Audit",
							action: "allowAudit",
							data: dataItem,
						});
					}

					return (
						<td>
							<Button
								size="small"
								icon="eye"
								onClick={() => handleAction({ action: "view", data: dataItem })}
							/>
							<TypedDropDownButton
								size="small"
								icon="more-vertical"
								items={items}
								itemRender={StatusItemRender}
								onItemClick={(x) => handleAction(x.item)}
								popupSettings={{ animate: false }}
							/>
						</td>
					);
				},
				field: "actions",
				width: "70px",
			},
		];
		return _columns;
	}, [getPossibleStatuses, handleAction, getLegColor]);
};

const useFetchData = (rangeValues: SelectionRange, statusValues: number[]) => {
	const _legs = useQuery({
		queryKey: [
			"jobApi.leg.legV2List",
			rangeValues.start,
			rangeValues.end,
			statusValues,
		],
		queryFn: () =>
			jobApi.leg
				.legV2List({
					DateFrom: rangeValues.start?.toISOString(),
					DateTo: rangeValues.end?.toISOString(),
					StatusIds: statusValues,
				})
				.then((x) => x.data),
		initialData: [],
		refetchInterval: 1000 * 30, // 30 seconds
	});
	const _jobTypes = useQuery({
		queryKey: ["jobApi.jobType.jobTypeList"],
		queryFn: () => jobApi.jobType.jobTypeList({}).then((x) => x.data.data),
		initialData: [],
	});
	const legs = useMemo(
		() =>
			_legs.data.map((item) => {
				const collectionDate = new Date(item.collectionDate ?? "");
				const deliveryDate = new Date(item.deliveryDate ?? "");
				const status = item.status ?? 0;
				const leg: Leg = {
					id: item.id ?? 0,
					actions: item.actions as LegActions,
					jobId: item.jobId ?? undefined,
					loadId: item.loadId ?? undefined,
					number: item.uniqueId ?? "",
					jobType:
						_jobTypes.data.find((x) => x.id === item.jobTypeId)?.name ?? "",
					type: item.type ?? 0,
					typeName: legTypeNames[item.type ?? 0] ?? "",
					customerName: item.customerName ?? "",
					assignedToName: item.assignedToName ?? "",
					collectionLocationName: item.collectionLocationName ?? "",
					collectionDate,
					deliveryLocationName: item.deliveryLocationName ?? "",
					deliveryDate,
					driverName: item.driverName ?? "",
					subcontractorName: item.subcontractorName ?? "",
					supplierInvoiceType:
						supplierInvoiceTypeNames[item.supplierInvoice ?? 0] ?? "",
					status,
					collectionDateString: toDatetimeString(collectionDate),
					deliveryDateString: toDatetimeString(deliveryDate),
					statusString:
						legStatusTypeNames[status as keyof typeof legStatusTypeNames] ?? "",
					currencyCode: item.currencyCode ?? undefined,
					cost: item.cost ?? undefined,
				};
				return leg;
			}),
		[_jobTypes.data, _legs.data],
	);
	return { legs, retry: _legs.refetch, loading: _legs.isFetching };
};
const useHandleAction = (
	retry: () => void,
	toggleSelectGoodsModal: (
		legId: number,
		callback: (goodsId: number) => Promise<unknown>,
	) => void,
	showAuditFor: (id: string | number) => void,
) => {
	const navigate = useNavigate();
	const location = useLocation();
	return useCallback(
		(item: StatusItem): void => {
			const itemStatus = item.status;
			switch (item.action) {
				case "view":
					if (item.data.jobId) {
						navigate(`/jobs/${item.data.jobId}`, {
							state: { from: location.pathname },
						});
					} else if (item.data.loadId) {
						navigate(`/loads/${item.data.loadId}`, {
							state: { from: location.pathname },
						});
					}
					break;
				case "cancel":
					toasted(
						jobApi.leg
							.legChangeStatusCreate({
								legId: item.data.id,
								status: LegStatusType.Cancelled,
							})
							.then(retry),
						"Canceling Leg",
					);
					break;
				case "delete":
					toasted(
						jobApi.leg
							.legChangeStatusCreate({
								legId: item.data.id,
								status: LegStatusType.Cancelled,
							})
							.then(retry),
						"Deleting Leg",
					);
					break;
				case "pause":
					toasted(
						jobApi.leg
							.legChangeStatusCreate({
								legId: item.data.id,
								status: LegStatusType.Paused,
							})
							.then(retry),
						"Pausing Leg",
					);
					break;
				case "resume":
					toasted(
						jobApi.leg
							.legChangeStatusCreate({
								legId: item.data.id,
								status: LegStatusType.Underway,
							})
							.catch(() => {
								jobApi.leg.legChangeStatusCreate({
									legId: item.data.id,
									status: LegStatusType.New,
								});
							})
							.then(retry),
						"Resuming Leg",
					);
					break;
				case "allowChangeStatus":
					if (!itemStatus) {
						console.error("Status not found");
						return;
					}
					toasted(
						jobApi.leg
							.legChangeStatusCreate({
								legId: item.data.id,
								status: itemStatus,
							})
							.then(retry),
						"Changing Status",
					);
					break;
				case "allowDelete":
					toasted(
						jobApi.leg.deleteLeg(item.data.id).then(retry),
						"Deleting Leg",
					);
					break;
				case "allowEdit":
					// purposefully not implemented
					throw new Error("Not implemented");
				case "allowGenerateDeliveryTicket":
					// purposefully not used
					toggleSelectGoodsModal(item.data.id, async (goodsId) =>
						toasted(
							jobApi.leg
								.legReportDeliveryTicketDetail(item.data.id, goodsId, {
									format: "blob",
								})
								.then((x) => window.open(URL.createObjectURL(x.data), "_blank"))
								.finally(retry),
							"Generating Delivery Ticket",
						),
					);
					break;
				case "allowGenerateCollectionNote":
					// purposefully not used
					toggleSelectGoodsModal(item.data.id, async (goodsId) =>
						toasted(
							jobApi.leg
								.legReportCollectionNoteDetail(item.data.id, goodsId, {
									format: "blob",
								})
								.then((x) => window.open(URL.createObjectURL(x.data), "_blank"))
								.finally(retry),
							"Generating Collection Note",
						),
					);
					break;
				case "allowGenerateSubcontractorOrder":
					// purposefully not used
					toasted(
						jobApi.leg
							.legReportSubcontractorOrderDetail(item.data.id, {
								format: "blob",
							})
							.then((x) => window.open(URL.createObjectURL(x.data), "_blank"))
							.finally(retry),
						"Generating Subcontractor Order",
					);
					break;
				case "allowAudit":
					showAuditFor(item.data.id);
					break;
				default:
					// biome-ignore lint/correctness/noVoidTypeReturn: To make sure we handle all cases
					return item.action;
			}
		},
		[location.pathname, navigate, toggleSelectGoodsModal, showAuditFor, retry],
	);
};
const useExtraFilters = () => {
	const [dateRangeEl, rangeValues] = useGenericDateRangePicker("Legs");
	const [filterStatusEl, statusValues] = useGenericLegStatusFilter("Legs");
	return [
		<Fragment key="extraFilters">
			{filterStatusEl}
			{dateRangeEl}
		</Fragment>,
		rangeValues,
		statusValues,
	] as const;
};
export const LegsPage2 = () => {
	useTitle("Legs");
	const { showAuditFor, auditDialog } = useAuditForm(TableNameType.Leg);
	const [extraFilters, rangeValues, statusValues] = useExtraFilters();
	const { legs, retry, loading } = useFetchData(rangeValues, statusValues);
	const [toggleSelectGoodsModal, selectGoodsModal] = useGoodsSelector();
	const handleAction = useHandleAction(
		retry,
		toggleSelectGoodsModal,
		showAuditFor,
	);
	const extraColumns = useExtraColumns(handleAction);
	return (
		<>
			{auditDialog}
			{selectGoodsModal}
			<LegsGrid
				data={legs}
				extraColumns={extraColumns}
				extraFilters={extraFilters}
				refresh={retry}
				loading={loading}
				footer={{
					cost: (data) => (
						<td>
							{toCurrency(
								sumBy(
									data.filter(
										// TODO https://celerum.atlassian.net/browse/TLS4-808 NEEDS TO BE REMOVED ONCE THE BACKEND IS FIXED
										(item) => item.status !== LegStatusType.Cancelled,
									),
									"cost",
								) || 0,
							)}
						</td>
					),
				}}
			/>
		</>
	);
};
