import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, array, date, number, object, string } from "yup";
import { Flex } from "./GenericFlex";
import {
	GenericFormButtons,
	InputDate,
	InputMultiSelect,
	InputSelect,
	InputText,
	InputTextArea,
	type LoadOptionsFn,
} from "./GenericForm";
import { GenericPage } from "./GenericPage";
import {
	type TypedGridColumnProps,
	jobApi,
	noInvalidDate,
	toDateString,
	toDatetimeString,
	toasted,
	useLookupConstraints,
	useLookupSubcontractors,
	useLookupTrailerStatuses,
	useLookupTrailerTypes,
} from "./helpers";
import { toCell } from "./helpersReact";
import { DocumentSchema, useDocumentsForm } from "./useDocumentsForm";

const TrailerSchema = object({
	id: number().label("ID"),
	name: string().required().label("Name"),
	chassisNo: string().label("Chassis No"),
	constraintIds: array()
		.of(number().required().label("Constraint"))
		.label("Constraints"),
	subcontractorId: number().nullable().label("Subcontractor"),
	europeanRegistrationNumber: string().label("European Registration"),
	fleetNumber: string().label("Fleet Number"),
	ministryNumber: string().label("Ministry Number"),
	nextInspectionDate: date()
		.transform(noInvalidDate)
		.label("Next Inspection Date"),
	nextMOTDate: date().transform(noInvalidDate).label("Next MOT Date"),
	notes: string().label("Notes"),
	trailerTypeId: number().required().label("Type"),
	documents: array()
		.of(DocumentSchema)
		.optional()
		.default([])
		.label("Documents"),
});

type TrailerFormObject = InferType<typeof TrailerSchema>;

type TrailerFormProps = {
	defaultValues?: Partial<TrailerFormObject>;
	onSubmit: (data: TrailerFormObject) => void;
	lookupSubcontractors: LoadOptionsFn;
	lookupTrailerTypes: LoadOptionsFn;
	lookupConstraints: LoadOptionsFn;
	lookupTrailerStatuses: LoadOptionsFn;
};

const TrailerForm = ({
	defaultValues,
	onSubmit,
	lookupSubcontractors,
	lookupTrailerTypes,
	lookupConstraints,
}: TrailerFormProps) => {
	const { handleSubmit, reset, control } = useForm<TrailerFormObject>({
		resolver: yupResolver(TrailerSchema),
		defaultValues,
	});

	return (
		<form
			className="k-form"
			onSubmit={handleSubmit((data) => onSubmit?.(data))}
		>
			<Flex>
				<div>
					<InputText control={control} schema={TrailerSchema} name="name" />
					<InputSelect
						control={control}
						schema={TrailerSchema}
						name="trailerTypeId"
						loadOptions={lookupTrailerTypes}
					/>
					<InputText
						control={control}
						schema={TrailerSchema}
						name="europeanRegistrationNumber"
					/>
					<InputText
						control={control}
						schema={TrailerSchema}
						name="fleetNumber"
					/>
					<InputText
						control={control}
						schema={TrailerSchema}
						name="ministryNumber"
					/>
					<InputMultiSelect
						control={control}
						schema={TrailerSchema}
						name="constraintIds"
						loadOptions={lookupConstraints}
					/>
				</div>
				<div>
					<InputText
						control={control}
						schema={TrailerSchema}
						name="chassisNo"
					/>
					<InputDate
						control={control}
						schema={TrailerSchema}
						name="nextInspectionDate"
					/>
					<InputDate
						control={control}
						schema={TrailerSchema}
						name="nextMOTDate"
					/>
					<InputTextArea
						control={control}
						schema={TrailerSchema}
						name="notes"
					/>
					<InputSelect
						control={control}
						schema={TrailerSchema}
						name="subcontractorId"
						loadOptions={lookupSubcontractors}
					/>
				</div>
			</Flex>
			<GenericFormButtons onReset={() => reset(defaultValues)} />
		</form>
	);
};

const TrailerFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<TrailerFormProps, "onSubmit" | "defaultValues">) => {
	const lookupSubcontractors = useLookupSubcontractors();
	const lookupConstraints = useLookupConstraints();
	const lookupLegStatuses = useLookupTrailerStatuses();
	const lookupTrailerTypes = useLookupTrailerTypes();
	return (
		<TrailerForm
			defaultValues={defaultValues}
			lookupSubcontractors={lookupSubcontractors}
			lookupConstraints={lookupConstraints}
			lookupTrailerStatuses={lookupLegStatuses}
			lookupTrailerTypes={lookupTrailerTypes}
			onSubmit={async (data) => {
				const { id, ...rest } = data;
				const other = {
					nextInspectionDate: rest.nextInspectionDate?.toISOString(),
					nextMOTDate: rest.nextMOTDate?.toISOString(),
				};
				const processData = async () => {
					if (id) await jobApi.trailer.trailerUpdate({ id, ...rest, ...other });
					else await jobApi.trailer.trailerCreate({ ...rest, ...other });
					onSubmit(data);
				};
				toasted(processData(), id ? "Updating Trailer" : "Creating Trailer");
			}}
		/>
	);
};

type Trailer = TrailerFormObject & {
	id: number;
	constraintsString: string;
	subcontractorString: string;
	trailerTypeString: string;
	nextInspectionDateString: string;
	nextMOTDateString: string;
};

const defaultColumns: TypedGridColumnProps<Trailer>[] = [
	{ field: "name", title: "Name" },
	{ field: "chassisNo", title: "Chassis No" },
	{ field: "constraintsString", title: "Constraints" },
	{ field: "subcontractorString", title: "Subcontractor" },
	{ field: "europeanRegistrationNumber", title: "European Registration" },
	{ field: "fleetNumber", title: "Fleet Number" },
	{ field: "ministryNumber", title: "Ministry Number" },
	{
		field: "nextInspectionDate",
		title: "Next Inspection Date",
		cell: ({ dataItem }) => toCell(dataItem.nextInspectionDateString),
	},
	{
		field: "nextMOTDate",
		title: "Next MOT Date",
		cell: ({ dataItem }) => toCell(dataItem.nextMOTDateString),
	},
	{ field: "notes", title: "Notes" },
	{ field: "trailerTypeString", title: "Trailer Type" },
];

const useFetchData = (): ComponentProps<
	typeof GenericPage<Trailer>
>["data"] => {
	const _trailers = useQuery({
		queryKey: ["jobApi.trailer.trailerList"],
		queryFn: () => jobApi.trailer.trailerList({}).then((x) => x.data.data),
		initialData: [],
	});

	const trailers = useMemo(
		() =>
			_trailers.data.map((x): Trailer => {
				const nextInspectionDate = dayjs(x.nextInspectionDate).toDate();
				const nextMOTDate = dayjs(x.nextMOTDate).toDate();
				return {
					id: x.id,
					name: x.name,
					chassisNo: x.chassisNo ?? "",
					constraintIds: x.constraints.map((x) => x.id),
					constraintsString: x.constraints.map((x) => x.name).join(", "),
					subcontractorId: x.subcontractor?.id ?? undefined,
					subcontractorString: x.subcontractor?.name ?? "",
					europeanRegistrationNumber: x.europeanRegistrationNumber ?? "",
					fleetNumber: x.fleetNumber ?? "",
					ministryNumber: x.ministryNumber ?? "",
					notes: x.notes ?? undefined,
					trailerTypeId: x.trailerType.id,
					trailerTypeString: x.trailerType.name ?? "",
					nextInspectionDate,
					nextInspectionDateString: toDatetimeString(nextInspectionDate),
					nextMOTDate,
					nextMOTDateString: toDateString(nextMOTDate),
					documents: x.documents,
				};
			}),
		[_trailers.data],
	);

	return {
		data: trailers,
		retry: _trailers.refetch,
		loading: _trailers.isFetching,
	};
};

export const TrailersPage2 = () => {
	const data = useFetchData();
	const { showDocumentsFor, documentsForm } = useDocumentsForm();

	const handleDelete = (id: number) =>
		toasted(
			jobApi.trailer.trailerDelete(id).then(data.retry),
			"Deleting Trailer",
		);

	const getForm = (
		id: number | undefined,
		onSubmit: (data: TrailerFormObject) => void,
	) => {
		let defaultValues: Partial<TrailerFormObject> = {
			nextInspectionDate: dayjs().startOf("d").add(1, "y").toDate(),
			nextMOTDate: dayjs().startOf("d").add(1, "y").toDate(),
		};

		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};

		return (
			<TrailerFormWithDTO onSubmit={onSubmit} defaultValues={defaultValues} />
		);
	};

	const extraActions = useMemo(
		() => [
			{
				name: "View Documents",
				onClick: (x: Trailer) => {
					showDocumentsFor(x.id, 2, x.documents, () => {
						data.retry();
					});
				},
			},
		],
		[showDocumentsFor, data.retry],
	);

	return (
		<>
			{documentsForm}
			<GenericPage
				pageTitle="Trailers"
				name="Trailer"
				data={data}
				onDelete={handleDelete}
				defaultColumns={defaultColumns}
				getForm={getForm}
				extraActions={extraActions}
			/>
		</>
	);
};
